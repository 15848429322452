.App {
  text-align: center;
  font-family: Arial, sans-serif;
  padding: 20px;
}

.donation-box {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  margin: 20px;
}

form {
  margin: 20px 0;
}

input, select, button {
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

input {
  width: 200px;
}

.blue-btn {
  background-color: blue;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.blue-btn:hover {
  background-color: darkblue;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin: 10px 0;
}

button {
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}
